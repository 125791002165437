.header {
  text-align: center;
}
.centered-spinner-txt {
  margin-left: 5px;
}

.main-button {
  margin-top: 20px;
}

.small-button {
  margin-bottom: 10px;
}

.bg-stripes-danger {
  background: repeating-linear-gradient(
  -55deg,
  #ffd6d6,
  #ffd6d6 10px,
  #ffffff 10px,
  #ffffff 20px
);
}
